//Mobile Dropdown scroll fix
// For RadGrid - .RadGrid .RadTouchExtender
// For RadScheduler - .rsContentScrollArea.RadTouchExtender
// For RadComboBox - .rcbScroll
// For RadDropDownList - .rddlList
//https://www.telerik.com/support/kb/aspnet-ajax/details/cannot-scroll-telerik-control-in-ios-11.3---the-page-scrolls-instead
function fixGridSchedulerComboBoxDropDownList() {
    var fixed = $telerik.$(".RadGrid .RadTouchExtender, .rsContentScrollArea.RadTouchExtender, .rcbScroll, .rddlList");
    fixed.on('touchmove', function (e) {
        e.preventDefault();
    });
}
if (typeof (Sys) !== "undefined" && typeof ($telerik) !== "undefined" && typeof ($telerik.$) !== "undefined") { Sys.Application.add_load(fixGridSchedulerComboBoxDropDownList); }
